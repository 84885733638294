/**
 * adudit 列表审核功能
 * 为了【列表审核】功能而做的公用函数
 * @auhtor suyonggang
 */
export default {
    data() {
        return {

            aduditAPI: [],

            submitLoading: false,
            form: {
                checkStatus: null,
                checkResult: null
            },
            rules: {
                checkStatus: {
                    required: true,
                    message: "不能为空",
                    trigger: "change"
                },
                checkResult: {
                    required: true,
                    message: "不能为空",
                    trigger: "blur"
                }
            }
        }
    },
    methods: {
        onClose() {
            this.$refs["form"].resetFields();
            this.auditDialogVisible = false
        },
        //提交表单
        async onSubmit(isGoNext) {
            let self = this;
            let item = this.aduditAPI[2]
            if (self.submitLoading) return; //防止重复提交
            self.$refs.form.validate(async valid => {
                if (!valid) return;
                self.submitLoading = true;
                try {

                    let aduditId = self.aduditAPI[1]
                    let params = {
                        [aduditId]: item,
                        checkStatus: self.form.checkStatus,
                        checkResult: self.form.checkResult
                    }
                    if (self.aduditAPI[4] === 0 || self.aduditAPI[4] === 1 || self.aduditAPI[4] === 2) {
                        params.type = self.aduditAPI[4];
                        params.checkStatus = self.form.checkStatus + ''
                    }
                    await self.aduditAPI[0](params);
                    self.$notify({
                        title: "成功",
                        message: `${self.nav[0].name}成功`,
                        type: "success"
                    });

                    if (isGoNext !== true) {
                        self.onClose();
                        self.$search(self[self.aduditAPI[3]]);
                    } else {
                        self.form = {};
                        this.$refs["form"].resetFields();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    self.submitLoading = false;
                }
            });
        },
    }
}